import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import postAxios from "../../../../services/axios-post";
import patchAxios from "../../../../services/axios-patch";
import getAxios from "../../../../services/axios-get";
import deleteAxios from "../../../../services/axios-delete";

Vue.use(VueAxios, axios);

export default {
  data() {
    return {
      type: null,
      alertMsg: null,
      pageCount: 0,
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      addUserPermission: {
        name: "",
        description: "",
        userCategory: "",
        order: "",
      },
      editUserPermission: {
        id: "",
        name: "",
        code: "",
        userCategory: "",
        description: "",
        order: "",
      },
      delUserPermission: {
        id: "",
        name: "",
      },
      nameSearch: "",
      total: 0,
      addDialog: false,
      editDialog: false,
      deleteDialog: false,
      token: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "Description", value: "description" },
        { text: "Order", value: "order" },
        { text: "User Category", value: "userCategory" },
        { text: "Edit" },
        { text: "Delete" },
      ],
      rules: {
        name: [v => !!v || "Name required"],
        description: [v => !!v || "Description required"],
        userCategory: [v => !!v || "User category required"],
        order: [v => !!v || "Order required"],
      },
      permissions: [],
      accType: [
        { text: "CU", value: 0 },
        { text: "SP", value: 1 },
        { text: "ADM", value: 2 },
        { text: "PARTNER", value: 3 },
      ],
    };
  },
  mounted() {
    this.fetchCredentials();
    this.fetchUserPermission();
  },
  methods: {
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.fetchUserPermission();
    },
    delButtonClick(item) {
      this.delUserPermission.id = item.id;
      this.delUserPermission.name = item.name;
      this.deleteDialog = true;
    },
    editButtonClick(item) {
      this.editUserPermission.id = item.id;
      this.editUserPermission.code = item.code;
      this.editUserPermission.name = item.name;
      this.editUserPermission.userCategory = item.userCategory
        ? this.accType.find(type => type.text === item.userCategory).value
        : null;
      this.editUserPermission.description = item.description;
      this.editUserPermission.order = item.order;
      this.editDialog = true;
    },
    resetAdd() {
      this.addUserPermission.name = null;
      this.addUserPermission.description = null;
      this.addUserPermission.userCategory = null;
      this.addUserPermission.order = null;
    },
    resetEdit() {
      this.editUserPermission.id = null;
      this.editUserPermission.code = null;
      this.editUserPermission.name = null;
      this.editUserPermission.description = null;
      this.editUserPermission.userCategory = null;
      this.editUserPermission.order = null;
    },
    doDelUserPermission() {
      const self = this;
      deleteAxios(
        `${process.env.VUE_APP_SERVER_URL}/baseDataSystem/delAccess?accessId=${this.delUserPermission.id}`
      )
        .then(res => {
          if (res.data) {
            self.deleteDialog = false;
            self.fetchUserPermission();
            self.$message({
              message: "User Permission Deleted Successfully!",
              type: "success",
            });
          }
        })
        .catch(err => {
          console.log(err);
          self.$message({
            message: "Error!",
            type: "error",
          });
        });
    },
    async doEditUserPermission() {
      const self = this;
      if (this.$refs.form.validate()) {
        patchAxios(
          `${process.env.VUE_APP_SERVER_URL}/baseDataSystem/updAccess`,
          this.editUserPermission,
          self.token
        )
          .then(res => {
            if (res.data) {
              self.editDialog = false;
              self.fetchUserPermission();
              self.resetEdit();
              self.showAlert("success", "User Permission Edited Successfully!");
            }
          })
          .catch(error => {
            self.editDialog = false;
            self.showAlert(
              "error",
              `Please verify User Category and Order, ${error}`
            );
          });
      }
    },
    async doAddUserPermission() {
      const self = this;
      try {
        if (this.$refs.form.validate()) {
          postAxios(
            `${process.env.VUE_APP_SERVER_URL}/baseDataSystem/addAccess`,
            this.addUserPermission,
            self.token
          )
            .then(res => {
              if (res.data) {
                self.addDialog = false;
                self.fetchUserPermission();
                self.resetAdd();
                self.showAlert("success", "New User Permission Added!");
              }
            })
            .catch(error => {
              self.addDialog = false;
              self.showAlert(
                "error",
                `Please verify User Category and Order, ${error}`
              );
            });
        }
      } catch (e) {
        console.error(e);
        self.$message({
          message: "Error!",
          type: "error",
        });
      }
    },
    searchType() {
      this.fetchUserPermission();
    },
    handlePageChange(newPage) {
      this.pagination.page = newPage;
      this.fetchCars();
    },
    handlePageSizeChange(newPageSize) {
      this.pagination.limit = newPageSize;
      const newPage = this.total / newPageSize;
      if (this.pagination.page > newPage) {
        this.pagination.page = Math.ceil(newPage);
      }
      this.fetchCars();
    },
    fetchUserPermission() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: self.pagination.limit,
        searchContent: self.nameSearch,
        sort: "order",
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/baseDataSystem/access`,
        params,
        self.token
      )
        .then(res => {
          if (res.data) {
            self.permissions = res.data.data.content;
            self.pagination.noOfPage = res.data.pageNumber;
          }
        })
        .catch(error => {
          self.showAlert("error", error);
        });
    },
    fetchCredentials() {
      var self = this;
      const token = localStorage.getItem("jwt");
      if (token) {
        self.token = token;
      } else {
        self.showAlert("error", "unauthorized..");
      }
    },
    showAlert(type, message) {
      this.type = type;
      this.alertMsg = message;
      let timer = this.showAlert.timer;
      if (timer) {
        clearTimeout(timer);
      }
      this.showAlert.timer = setTimeout(() => {
        this.type = null;
      }, 4000);
      let t = this.showAlert.t;
      if (t) {
        clearInterval(t);
      }
    },
  },
};
